import "./TopLogo.scss";
import "./Adaptations.scss";

import TopLogoNisekoGolfEstatesImage from "../../../../static/images/NisekoLogo.svg";
import TopLogoNisekoImage from "../../../../static/images/NisekoLogo2.svg";
import backgroundOfImg from "../../../../static/images/top_logo_rectangle.png"

export function TopLogoNisekoGolfEstates() {
    return (
        <div className="top-logo top-logo-niseko-estates">
            <img
                className="top-logo-niseko-estates-image"
                src={TopLogoNisekoGolfEstatesImage}
                alt="alaqtar-logo"
            />
            <img className="background-of-image" src={backgroundOfImg} alt="" />
        </div>
    );
}

export function TopLogoNiseko() {
    return (
        <div className="top-logo top-logo-niseko">
            <img
                className="top-logo-alaqtar-image"
                src={TopLogoNisekoImage}
                alt="alaqtar-logo"
            />
        </div>
    );
}
