import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../Main";

import "./GolfEstates.scss";
import "./Adaptations.scss";
import { zone5_land_plots } from "./land_plots";

import Reel from "../../../../components/main/reel/Reel";
import BackButton from "../../../../components/main/menu/back-button/BackButton";

import startFrame from "../../../../static/images/contents/golf-estates/temp.jpg";

import { TopLogoNiseko } from "../../../../components/main/menu/top-logo/TopLogo";

export default function Zone5() {
    const [
        changeContent,
        setModalContent,
        pageClicked,
        setEnquireWindowContent,
    ] = useContext(MainContext);
    const [switchState, setSwitchState] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const onLoad = () => {
        setIsLoaded(true);
    };

    return (
        <div className="golf-estates" style={{ width: "100%", height: "100%" }}>
            <TopLogoNiseko />
            <BackButton
                to={"/en/"}
                setEnquireWindowContent={setEnquireWindowContent}
            />
            {/* <ServicesButton setModalContent={setModalContent} /> */}
            {/* {isLoaded && (
                <>
                    <div className="golf-estates-switches">
                        <Switch
                            switchChecked={switchState}
                            handleSwitchCheck={setSwitchState}
                            labelText={t("zone_5_switch_land_plot")}
                        />
                        <Switch
                            switchChecked={!switchState}
                            handleSwitchCheck={setSwitchState}
                            labelText={t("zone_5_switch_blocks")}
                        />
                    </div>
                    <PopUp title={t("tip_title")} desc={t("tip_desc")} />
                </>
            )} */}
            <Reel
                folder="static/contents/level_2/renders"
                amount={120}
                qualitySteps={1}
                defaultControlsPart={0.075}
                stops={zone5_land_plots(
                    setModalContent,
                    setEnquireWindowContent
                )}
                overlay_elements={null} // Soon
                wrapper_animation_key={switchState}
                startFrame={startFrame}
                onFullLoad={onLoad}
            />
        </div>
    );
}
