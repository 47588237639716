import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import "./ContactForm.scss";
import "./Adaptations.scss";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@react-hook/media-query";

import CrossThinImage from "../../../static/images/icons/cross-thin.svg";
import FullNameIcon from "../../../static/images/icons/user.svg";
import EmailIcon from "../../../static/images/icons/email.svg";

import SeparatingLineVertical from "../../../static/images/contact-form-line-vertical.svg";
import SeparatingLineHorizontal from "../../../static/images/contact-form-line-horizontal.svg";

import ElectricityImage from "../../../static/images/contact-form-electricity.svg";
import WaterImage from "../../../static/images/contact-form-water.svg";
import RoadsImage from "../../../static/images/contact-form-roads.svg";
import DrainageImage from "../../../static/images/contact-form-drainage.svg";
import i18next from "i18next";

import Lot3 from "../../../static/images/Lot 3.svg"

function ContactFormStatus() {
    return (
        <div className="contact-form-status">
            <div
                className="contact-form-status-title"
            >
                <span className="contact-form-status-title-text">
                    Your form has been submitted successfully!
                </span>
            </div>
            <div
                className="contact-form-status-desc"
            >
                <span className="contact-form-status-desc-text">
                    Thank you for contacting us!
                </span>
            </div>
        </div>
    );
}

function ContactFormField({ label, name, id, type, icon, placeholder}) {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className="contact-form-field">
            <div
                className="contact-form-field-label"
            >
                <span className="contact-form-field-label-text">{label}</span>
            </div>
            <div className="contact-form-field-input-wrapper">
                {/* <img
                    src={icon}
                    alt={`${label} icon`}
                    className="contact-form-field-icon"
                /> */}
                <input
                    className="contact-form-field-input"
                    name={name}
                    id={id}
                    type={type}
                    required
                    placeholder={isFocused ? "" : placeholder}
                    onFocus={() => setIsFocused(true)}
                    onBlur={(e) => {
                        if (e.target.value === "") {
                            setIsFocused(false);
                        }
                    }}
                />
            </div>
        </div>
    );
}

function ContactFormPhoneField({
    label,
    placeholder,
    phoneInput,
    setPhoneInput,
}) {

    // const handlePhoneChange = (value) => {
    //     console.log('Введенное значение:', value);
    //     const digitsOnly = value ? value.replace(/\D/g, '') : '';
    //     console.log('Только цифры:', digitsOnly);
    //     if (digitsOnly.length <= 11) {
    //         setPhoneInput(value);
    //     }
    // };
    return (
        <div className="contact-form-field">
            <div
                className="contact-form-field-label"
            >
                <span className="contact-form-field-label-text">{label}</span>
            </div>
            <div className="contact-form-field-input-wrapper">
                <PhoneInput
                    placeholder={placeholder}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="JP"
                    value={phoneInput}
                    onChange={setPhoneInput}
                    // onChange={handlePhoneChange}
                    withCountryCallingCode={true}
                />
            </div>
        </div>
    );
}

function ContactFormInfo({ label, value }) {

    return (
        <div
            className="contact-form-info"
        >
            <span className="contact-form-info-label">{label}</span>
            <span className="contact-form-info-value">{value}</span>
        </div>
    );
}

function ContactFormText({ text, className }) {
    return (
        <div className={"contact-form-text " + (className ? className : "")}>
            <span className="contact-form-text-label">{text}</span>
        </div>
    );
}

function ContactFormIcon({ src }) {
    return (
        <div className="contact-form-icon">
            <img src={src} alt="form-icon" />
        </div>
    );
}

export default function ContactForm({
    form_id,
    title,
    closeFunc,
    lot,
    total_lot_size,
    forest_area,
    buildable_area,
    max_suggested_villa_floor_area,
    image,
    status_of_lot,
    price
}) {
    const isMobile = useMediaQuery("(max-width: 600px)");

    const [status, setStatus] = useState("");

    const [phoneInput, setPhoneInput] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);

        formData.append("Info", title);
        formData.append("Phone", phoneInput);

        try {
            const response = await fetch(`https://formspree.io/f/${form_id}`, {
                method: "POST",
                body: formData,
                headers: {
                    Accept: "application/json",
                },
            });
            // https://alaqtar-demo5-api.visengine.app/
            await fetch(
                `https://niseko-api.visengine.app/spreadsheet/add_row/`,
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Accept: "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setStatus("Form submitted successfully!");
                form.reset();
            } else {
                setStatus("Failed to submit the form. Please try again.");
            }
        } catch (error) {
            setStatus("Failed to submit the form. Please try again.");
        }
    };

    return (
        <div
            className="contact-form"
        >
            <div className="contact-form-close" onClick={closeFunc}>
                <img src={CrossThinImage} alt="cross-close" />
            </div>
            {status ? (
                <ContactFormStatus />
            ) : (
                <>
                    <div className="contact-form-left">
                        <img src={image} alt="" />
                        <div className="lot-size-block">
                            <span style={{fontWeight: 700,}}>Lot {lot}</span>
                            |
                            <span style={{fontWeight: 500,}}>{total_lot_size}sqm</span>
                            <span
                                className={
                                    status_of_lot === "available"
                                        ? "price"
                                        : "status-span"
                                }
                                style={{
                                    backgroundColor:
                                        status_of_lot === "sold"
                                            ? "#40585A"
                                            : status_of_lot === "held"
                                            ? "#99B874"
                                            : "",
                                    color: status_of_lot === "sold" ? "#fff" : "",
                                }}
                            >
                                {status_of_lot === "available" ? price : status_of_lot}
                            </span>
                        </div>
                        <div className="info-inner">
                            <div className="info-inner-block">
                                <div>Total lot size</div>
                                <div>Forest area</div>
                            </div>
                            <div className="info-inner-block">
                                <div>{total_lot_size}</div>
                                <div>{forest_area}</div>
                            </div>
                            <div className="info-inner-block">
                                <div>Buildable area</div>
                                <div>Max villa floor area</div>
                            </div>
                            <div className="info-inner-block">
                                <div>{buildable_area}</div>
                                <div>{max_suggested_villa_floor_area}</div>
                            </div>
                        </div>
                        {/* {form_type === "land" ? (
                            <>
                                <div className="contact-form-left-group">
                                    <ContactFormText
                                        text={
                                            t("contact_form_block") +
                                            "\u00A0\u00A0" +
                                            block
                                        }
                                    />
                                    <img src={Lot3} alt="Lot Image" />
                                    <div
                                        className="contact-form-title"
                                        style={{
                                            fontFamily: t("font_salvager"),
                                        }}
                                    >
                                        <span className="contact-form-title-text">
                                            {title}
                                        </span>
                                    </div>
                                </div>
                                <ContactFormInfo
                                    label={t("unit_size")}
                                    value={unitSize + " " + t("square_meters")}
                                />
                                <div className="contact-form-position-info contact-form-position-info-nsew">
                                    <div>
                                        <ContactFormInfo
                                            label={t("contact_form_north")}
                                            value={north}
                                        />
                                        <ContactFormInfo
                                            label={t("contact_form_east")}
                                            value={east}
                                        />
                                    </div>
                                    <div>
                                        <ContactFormInfo
                                            label={t("contact_form_south")}
                                            value={south}
                                        />
                                        <ContactFormInfo
                                            label={t("contact_form_west")}
                                            value={west}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="contact-form-position-info contact-form-position-info-nsew">
                                    <ContactFormInfo
                                        label={t("contact_form_north")}
                                        value={north}
                                    />
                                    <ContactFormInfo
                                        label={t("contact_form_east")}
                                        value={east}
                                    />
                                    <ContactFormInfo
                                        label={t("contact_form_south")}
                                        value={south}
                                    />
                                    <ContactFormInfo
                                        label={t("contact_form_west")}
                                        value={west}
                                    />
                                </div> */}
                                {/* <div className="contact-form-left-group">
                                    <div className="contact-form-position-info">
                                        <ContactFormInfo
                                            label={t("contact_form_front1")}
                                            value={t(
                                                "contact_form_front_" +
                                                    front1.toLowerCase()
                                            )}
                                        />
                                        <ContactFormInfo
                                            label={t("contact_form_front2")}
                                            value={
                                                front2 === "nan"
                                                    ? "-"
                                                    : t(
                                                          "contact_form_front_" +
                                                              front2.toLowerCase()
                                                      )
                                            }
                                        />
                                    </div>
                                    <div className="contact-form-position-info">
                                        <ContactFormInfo
                                            label={t("contact_form_st_width_1")}
                                            value={
                                                st_width_1 + " " + t("meters")
                                            }
                                        />
                                        <ContactFormInfo
                                            label={t("contact_form_st_width_2")}
                                            value={
                                                st_width_2 === "0"
                                                    ? "-"
                                                    : st_width_2 +
                                                      " " +
                                                      t("meters")
                                            }
                                        />
                                    </div>
                                </div> */}
                            {/* </>
                        ) : (
                            form_type === "block" && (
                                <>
                                    <div
                                        className="contact-form-title"
                                    >
                                        <span className="contact-form-title-text">
                                            {title}
                                        </span>
                                    </div>
                                    <ContactFormInfo
                                        label={t("number_of_units")}
                                        value={numberOfUnits}
                                    />
                                    <ContactFormInfo
                                        label={t("block_size")}
                                        value={
                                            blockSize + " " + t("square_meters")
                                        }
                                    />
                                </>
                            )
                        )} */}

                        {/* <div className="contact-form-left-group">
                            {electricTransformer || form_type === "block" ? (
                                <ContactFormText
                                    text={t("contact_from_transformer_info")}
                                    className="contact-form-text-color contact-form-text-transformer"
                                />
                            ) : null}
                            <div className="contact-form-icons">
                                <ContactFormIcon src={ElectricityImage} />
                                <ContactFormIcon src={WaterImage} />
                                <ContactFormIcon src={RoadsImage} />
                                <ContactFormIcon src={DrainageImage} />
                            </div>
                        </div> */}
                    </div>
                    <div className="contact-form-separator">
                        <img
                            src={
                                isMobile
                                    ? SeparatingLineHorizontal
                                    : SeparatingLineVertical
                            }
                            alt="separating-line"
                        />
                    </div>
                    <div
                        className="contact-form-right"
                    >
                        <form onSubmit={handleSubmit}>
                            <ContactFormField
                                label={"Full Name"}
                                name="Name"
                                id="name"
                                type="text"
                                icon={FullNameIcon}
                                placeholder="Charlie Peterson"
                            />
                            <ContactFormField
                                label={"Email"}
                                name="Email"
                                id="email"
                                type="email"
                                icon={EmailIcon}
                                placeholder="petersoncharlie@gmail.com"
                            />
                            <ContactFormPhoneField
                                label={"Phone number"}
                                placeholder="+81 (660) 0000-0000"
                                phoneInput={phoneInput}
                                setPhoneInput={setPhoneInput}
                            />
                            <ContactFormField
                                label={"Country"}
                                name="Country"
                                id="country"
                                type="text"
                                icon={EmailIcon}
                                placeholder="United Kingdom"
                            />
                            <button
                                className="contact-form-submit"
                                type="submit"
                            >
                                send
                            </button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
}
