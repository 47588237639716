import "./SvgLabel.scss";

const calculateTextWidth = (text, fontSize, fontFamily, fontWeight) => {
    const font = fontSize + "px " + fontFamily;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontWeight} ${font}`;
    return context.measureText(text).width;
};

export default function SvgLabel({ coords, text = "", status, width }) {
    const labelText = status.toUpperCase();

    const indexTextWidth = calculateTextWidth(text, 27, "Inter", 400);
    const textWidth = calculateTextWidth(labelText, 13, "Inter", 700);
    const rectWidth = textWidth + 9;

    const classNameWithStatus = status.toLowerCase().replace(" ", "-");

    return (
        <g className="svg-label">
            {status === "available" ? (
                <text
                    className={
                        "svg-label-index-text svg-label-index-text-" +
                        classNameWithStatus
                    }
                    x={coords[0] - indexTextWidth / 2}
                    y={coords[1] + 10}
                >
                    {text}
                </text>
            ) : (
                <>
                    <text
                        className={
                            "svg-label-index-text svg-label-index-text-" +
                            classNameWithStatus
                        }
                        x={coords[0] - indexTextWidth / 2}
                        y={coords[1] + 5}
                    >
                        {text}
                    </text>
                    <rect
                        className={
                            "svg-label-background svg-label-background-" +
                            classNameWithStatus
                        }
                        x={coords[0] - rectWidth / 2}
                        y={coords[1] + 10}
                        width={rectWidth}
                        height="20"
                    />
                    <text
                        className={
                            "svg-label-text svg-label-text-" +
                            classNameWithStatus
                        }
                        x={coords[0] - textWidth / 2}
                        y={coords[1] + 24.7}
                    >
                        {labelText}
                    </text>
                </>
            )}
        </g>
    );
}
