import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import VR_C1 from "../../../../static/images/contents/golf-estates/vr/C1_Low.jpg";
import VR_C2 from "../../../../static/images/contents/golf-estates/vr/C2_Low.jpg";
import VR_C3 from "../../../../static/images/contents/golf-estates/vr/C3_Low.jpg";
import VR_C4 from "../../../../static/images/contents/golf-estates/vr/C4_Low.jpg";
import VR_C5 from "../../../../static/images/contents/golf-estates/vr/C5_Low.jpg";
import VR_C6 from "../../../../static/images/contents/golf-estates/vr/C6_Low.jpg";
import VR from "../../../../components/main/vr/VR";

//stop frame 6
import { ReactComponent as LandPlot_6_3 } from "./land_plots/6/land_plot_block_3.svg";
import { ReactComponent as LandPlot_6_4 } from "./land_plots/6/land_plot_block_4.svg";
import { ReactComponent as LandPlot_6_5 } from "./land_plots/6/land_plot_block_5.svg";
import { ReactComponent as LandPlot_6_6 } from "./land_plots/6/land_plot_block_6.svg";
import { ReactComponent as LandPlot_6_7 } from "./land_plots/6/land_plot_block_7.svg";
import { ReactComponent as LandPlot_6_8 } from "./land_plots/6/land_plot_block_8.svg";
import { ReactComponent as LandPlot_6_9 } from "./land_plots/6/land_plot_block_9.svg";
import { ReactComponent as LandPlot_6_10 } from "./land_plots/6/land_plot_block_10.svg";
import { ReactComponent as LandPlot_6_11 } from "./land_plots/6/land_plot_block_11.svg";
import { ReactComponent as LandPlot_6_12 } from "./land_plots/6/land_plot_block_12.svg";
import { ReactComponent as LandPlot_6_13 } from "./land_plots/6/land_plot_block_13.svg";
import { ReactComponent as LandPlot_6_14 } from "./land_plots/6/land_plot_block_14.svg";
import { ReactComponent as LandPlot_6_15 } from "./land_plots/6/land_plot_block_15.svg";
import { ReactComponent as LandPlot_6_16 } from "./land_plots/6/land_plot_block_16.svg";
import { ReactComponent as LandPlot_6_17 } from "./land_plots/6/land_plot_block_17.svg";
import { ReactComponent as LandPlot_6_18 } from "./land_plots/6/land_plot_block_18.svg";
import { ReactComponent as LandPlot_6_19 } from "./land_plots/6/land_plot_block_19.svg";
import { ReactComponent as LandPlot_6_20 } from "./land_plots/6/land_plot_block_20.svg";
import { ReactComponent as LandPlot_6_21 } from "./land_plots/6/land_plot_block_21.svg";
import { ReactComponent as LandPlot_6_22 } from "./land_plots/6/land_plot_block_22.svg";
import { ReactComponent as LandPlot_6_23 } from "./land_plots/6/land_plot_block_23.svg";
import { ReactComponent as LandPlot_6_24 } from "./land_plots/6/land_plot_block_24.svg";
import { ReactComponent as LandPlot_6_25 } from "./land_plots/6/land_plot_block_25.svg";
import { ReactComponent as LandPlot_6_26 } from "./land_plots/6/land_plot_block_26.svg";
import { ReactComponent as VR360_6_1 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_1.svg";
import { ReactComponent as VR360_6_2 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_2.svg";
import { ReactComponent as VR360_6_3 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_3.svg";
import { ReactComponent as VR360_6_4 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_4.svg";
import { ReactComponent as VR360_6_5 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_5.svg";
import { ReactComponent as VR360_6_6 } from "../../../../static/images/contents/golf-estates/icons/6/vr360_6.svg";

//stop frame 27
import { ReactComponent as LandPlot_27_3 } from "./land_plots/27/land_plot_block_3.svg";
import { ReactComponent as LandPlot_27_4 } from "./land_plots/27/land_plot_block_4.svg";
import { ReactComponent as LandPlot_27_5 } from "./land_plots/27/land_plot_block_5.svg";
import { ReactComponent as LandPlot_27_6 } from "./land_plots/27/land_plot_block_6.svg";
import { ReactComponent as LandPlot_27_7 } from "./land_plots/27/land_plot_block_7.svg";
import { ReactComponent as LandPlot_27_8 } from "./land_plots/27/land_plot_block_8.svg";
import { ReactComponent as LandPlot_27_9 } from "./land_plots/27/land_plot_block_9.svg";
import { ReactComponent as LandPlot_27_10 } from "./land_plots/27/land_plot_block_10.svg";
import { ReactComponent as LandPlot_27_11 } from "./land_plots/27/land_plot_block_11.svg";
import { ReactComponent as LandPlot_27_12 } from "./land_plots/27/land_plot_block_12.svg";
import { ReactComponent as LandPlot_27_13 } from "./land_plots/27/land_plot_block_13.svg";
import { ReactComponent as LandPlot_27_14 } from "./land_plots/27/land_plot_block_14.svg";
import { ReactComponent as LandPlot_27_15 } from "./land_plots/27/land_plot_block_15.svg";
import { ReactComponent as LandPlot_27_16 } from "./land_plots/27/land_plot_block_16.svg";
import { ReactComponent as LandPlot_27_17 } from "./land_plots/27/land_plot_block_17.svg";
import { ReactComponent as LandPlot_27_18 } from "./land_plots/27/land_plot_block_18.svg";
import { ReactComponent as LandPlot_27_19 } from "./land_plots/27/land_plot_block_19.svg";
import { ReactComponent as LandPlot_27_20 } from "./land_plots/27/land_plot_block_20.svg";
import { ReactComponent as LandPlot_27_21 } from "./land_plots/27/land_plot_block_21.svg";
import { ReactComponent as LandPlot_27_22 } from "./land_plots/27/land_plot_block_22.svg";
import { ReactComponent as LandPlot_27_23 } from "./land_plots/27/land_plot_block_23.svg";
import { ReactComponent as LandPlot_27_24 } from "./land_plots/27/land_plot_block_24.svg";
import { ReactComponent as LandPlot_27_25 } from "./land_plots/27/land_plot_block_25.svg";
import { ReactComponent as LandPlot_27_26 } from "./land_plots/27/land_plot_block_26.svg";
import { ReactComponent as VR360_27_1 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_1.svg";
import { ReactComponent as VR360_27_2 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_2.svg";
import { ReactComponent as VR360_27_3 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_3.svg";
import { ReactComponent as VR360_27_4 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_4.svg";
import { ReactComponent as VR360_27_5 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_5.svg";
import { ReactComponent as VR360_27_6 } from "../../../../static/images/contents/golf-estates/icons/27/vr360_6.svg";

//stop frame 62
import { ReactComponent as LandPlot_62_3 } from "./land_plots/62/land_plot_block_3.svg";
import { ReactComponent as LandPlot_62_4 } from "./land_plots/62/land_plot_block_4.svg";
import { ReactComponent as LandPlot_62_5 } from "./land_plots/62/land_plot_block_5.svg";
import { ReactComponent as LandPlot_62_6 } from "./land_plots/62/land_plot_block_6.svg";
import { ReactComponent as LandPlot_62_7 } from "./land_plots/62/land_plot_block_7.svg";
import { ReactComponent as LandPlot_62_8 } from "./land_plots/62/land_plot_block_8.svg";
import { ReactComponent as LandPlot_62_9 } from "./land_plots/62/land_plot_block_9.svg";
import { ReactComponent as LandPlot_62_10 } from "./land_plots/62/land_plot_block_10.svg";
import { ReactComponent as LandPlot_62_11 } from "./land_plots/62/land_plot_block_11.svg";
import { ReactComponent as LandPlot_62_12 } from "./land_plots/62/land_plot_block_12.svg";
import { ReactComponent as LandPlot_62_13 } from "./land_plots/62/land_plot_block_13.svg";
import { ReactComponent as LandPlot_62_14 } from "./land_plots/62/land_plot_block_14.svg";
import { ReactComponent as LandPlot_62_15 } from "./land_plots/62/land_plot_block_15.svg";
import { ReactComponent as LandPlot_62_16 } from "./land_plots/62/land_plot_block_16.svg";
import { ReactComponent as LandPlot_62_17 } from "./land_plots/62/land_plot_block_17.svg";
import { ReactComponent as LandPlot_62_18 } from "./land_plots/62/land_plot_block_18.svg";
import { ReactComponent as LandPlot_62_19 } from "./land_plots/62/land_plot_block_19.svg";
import { ReactComponent as LandPlot_62_20 } from "./land_plots/62/land_plot_block_20.svg";
import { ReactComponent as LandPlot_62_21 } from "./land_plots/62/land_plot_block_21.svg";
import { ReactComponent as LandPlot_62_22 } from "./land_plots/62/land_plot_block_22.svg";
import { ReactComponent as LandPlot_62_23 } from "./land_plots/62/land_plot_block_23.svg";
import { ReactComponent as LandPlot_62_24 } from "./land_plots/62/land_plot_block_24.svg";
import { ReactComponent as LandPlot_62_25 } from "./land_plots/62/land_plot_block_25.svg";
import { ReactComponent as LandPlot_62_26 } from "./land_plots/62/land_plot_block_26.svg";
import { ReactComponent as VR360_62_1 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_1.svg";
import { ReactComponent as VR360_62_2 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_2.svg";
import { ReactComponent as VR360_62_3 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_3.svg";
import { ReactComponent as VR360_62_4 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_4.svg";
import { ReactComponent as VR360_62_5 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_5.svg";
import { ReactComponent as VR360_62_6 } from "../../../../static/images/contents/golf-estates/icons/62/vr360_6.svg";

//stop frame 89
import { ReactComponent as LandPlot_89_3 } from "./land_plots/89/land_plot_block_3.svg";
import { ReactComponent as LandPlot_89_4 } from "./land_plots/89/land_plot_block_4.svg";
import { ReactComponent as LandPlot_89_5 } from "./land_plots/89/land_plot_block_5.svg";
import { ReactComponent as LandPlot_89_6 } from "./land_plots/89/land_plot_block_6.svg";
import { ReactComponent as LandPlot_89_7 } from "./land_plots/89/land_plot_block_7.svg";
import { ReactComponent as LandPlot_89_8 } from "./land_plots/89/land_plot_block_8.svg";
import { ReactComponent as LandPlot_89_9 } from "./land_plots/89/land_plot_block_9.svg";
import { ReactComponent as LandPlot_89_10 } from "./land_plots/89/land_plot_block_10.svg";
import { ReactComponent as LandPlot_89_11 } from "./land_plots/89/land_plot_block_11.svg";
import { ReactComponent as LandPlot_89_12 } from "./land_plots/89/land_plot_block_12.svg";
import { ReactComponent as LandPlot_89_13 } from "./land_plots/89/land_plot_block_13.svg";
import { ReactComponent as LandPlot_89_14 } from "./land_plots/89/land_plot_block_14.svg";
import { ReactComponent as LandPlot_89_15 } from "./land_plots/89/land_plot_block_15.svg";
import { ReactComponent as LandPlot_89_16 } from "./land_plots/89/land_plot_block_16.svg";
import { ReactComponent as LandPlot_89_17 } from "./land_plots/89/land_plot_block_17.svg";
import { ReactComponent as LandPlot_89_18 } from "./land_plots/89/land_plot_block_18.svg";
import { ReactComponent as LandPlot_89_19 } from "./land_plots/89/land_plot_block_19.svg";
import { ReactComponent as LandPlot_89_20 } from "./land_plots/89/land_plot_block_20.svg";
import { ReactComponent as LandPlot_89_21 } from "./land_plots/89/land_plot_block_21.svg";
import { ReactComponent as LandPlot_89_22 } from "./land_plots/89/land_plot_block_22.svg";
import { ReactComponent as LandPlot_89_23 } from "./land_plots/89/land_plot_block_23.svg";
import { ReactComponent as LandPlot_89_24 } from "./land_plots/89/land_plot_block_24.svg";
import { ReactComponent as LandPlot_89_25 } from "./land_plots/89/land_plot_block_25.svg";
import { ReactComponent as LandPlot_89_26 } from "./land_plots/89/land_plot_block_26.svg";
import { ReactComponent as VR360_89_1 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_1.svg";
import { ReactComponent as VR360_89_2 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_2.svg";
import { ReactComponent as VR360_89_3 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_3.svg";
import { ReactComponent as VR360_89_4 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_4.svg";
import { ReactComponent as VR360_89_5 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_5.svg";
import { ReactComponent as VR360_89_6 } from "../../../../static/images/contents/golf-estates/icons/89/vr360_6.svg";

import LotImg1 from "../../../../static/images/contents/golf-estates/lots/1.jpg";
import LotImg2 from "../../../../static/images/contents/golf-estates/lots/2.jpg";
import LotImg3 from "../../../../static/images/contents/golf-estates/lots/3.jpg";
import LotImg5 from "../../../../static/images/contents/golf-estates/lots/5.jpg";
import LotImg6 from "../../../../static/images/contents/golf-estates/lots/6.jpg";
import LotImg7 from "../../../../static/images/contents/golf-estates/lots/7.jpg";
import LotImg8 from "../../../../static/images/contents/golf-estates/lots/8.jpg";
import LotImg9 from "../../../../static/images/contents/golf-estates/lots/9.jpg";
import LotImg10 from "../../../../static/images/contents/golf-estates/lots/10.jpg";
import LotImg11 from "../../../../static/images/contents/golf-estates/lots/11.jpg";
import LotImg12 from "../../../../static/images/contents/golf-estates/lots/12.jpg";
import LotImg15 from "../../../../static/images/contents/golf-estates/lots/15.jpg";
import LotImg16 from "../../../../static/images/contents/golf-estates/lots/16.jpg";
import LotImg17 from "../../../../static/images/contents/golf-estates/lots/17.jpg";
import LotImg18 from "../../../../static/images/contents/golf-estates/lots/18.jpg";
import LotImg19 from "../../../../static/images/contents/golf-estates/lots/19.jpg";
import LotImg20 from "../../../../static/images/contents/golf-estates/lots/20.jpg";
import LotImg21 from "../../../../static/images/contents/golf-estates/lots/21.jpg";
import LotImg22 from "../../../../static/images/contents/golf-estates/lots/22.jpg";
import LotImg23 from "../../../../static/images/contents/golf-estates/lots/23.jpg";
import LotImg25 from "../../../../static/images/contents/golf-estates/lots/25.jpg";
import LotImg26 from "../../../../static/images/contents/golf-estates/lots/26.jpg";
import LotImg27 from "../../../../static/images/contents/golf-estates/lots/27.jpg";
import LotImg28 from "../../../../static/images/contents/golf-estates/lots/28.jpg";

export const zone5_land_plots = (setModalContent, setEnquireWindowContent) => [
    //index 6
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_6_3,
                            className: null,
                            label: {
                                text: "Phase 3",
                                coords: [1267, 397],
                            },
                            infobox: {
                                type: "land_info",
                                index: 1,
                                lot: "1",
                                total_lot_size: "619 ",
                                forest_area: "337 ",
                                buildable_area: "198 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg1,
                            },
                            onClick: {
                                // action: "open_contact_form",
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_4,
                            className: null,
                            label: {
                                coords: [1136, 353],
                            },
                            infobox: {
                                type: "land_info",
                                index: 2,
                                lot: "2",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg2,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_5,
                            className: null,
                            label: {
                                coords: [1017, 307],
                            },
                            infobox: {
                                type: "land_info",
                                index: 3,
                                lot: "3",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "193 ",
                                max_suggested_villa_floor_area: "465-580 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg3,
                                price: "¥130,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_6,
                            className: null,
                            label: {
                                coords: [897, 296],
                            },
                            infobox: {
                                type: "land_info",
                                index: 5,
                                lot: "5",
                                total_lot_size: "667 ",
                                forest_area: "273 ",
                                buildable_area: "286 ",
                                max_suggested_villa_floor_area: "687-858 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg5,
                                price: "¥141,230,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_7,
                            className: null,
                            label: {
                                coords: [804, 246],
                            },
                            infobox: {
                                type: "land_info",
                                index: 6,
                                lot: "6",
                                total_lot_size: "662 ",
                                forest_area: "307 ",
                                buildable_area: "254 ",
                                max_suggested_villa_floor_area: "611-763 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg6,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_8,
                            className: null,
                            label: {
                                coords: [715, 212],
                            },
                            infobox: {
                                type: "land_info",
                                index: 7,
                                lot: "7",
                                total_lot_size: "666 ",
                                forest_area: "354 ",
                                buildable_area: "222 ",
                                max_suggested_villa_floor_area: "534-667 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg7,
                                price: "¥151,100,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_9,
                            className: null,
                            label: {
                                coords: [611, 222],
                            },
                            infobox: {
                                type: "land_info",
                                index: 8,
                                lot: "8",
                                total_lot_size: "611 ",
                                forest_area: "281 ",
                                buildable_area: "240 ",
                                max_suggested_villa_floor_area: "576-720 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg8,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_10,
                            className: null,
                            label: {
                                coords: [541, 318],
                            },
                            infobox: {
                                type: "land_info",
                                index: 9,
                                lot: "9",
                                total_lot_size: "611 ",
                                forest_area: "325 ",
                                buildable_area: "243 ",
                                max_suggested_villa_floor_area: "585-731 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg9,
                                price: "¥221,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_11,
                            className: null,
                            label: {
                                coords: [599, 397],
                            },
                            infobox: {
                                type: "land_info",
                                index: 10,
                                lot: "10",
                                total_lot_size: "619 ",
                                forest_area: "277 ",
                                buildable_area: "249 ",
                                max_suggested_villa_floor_area: "599-748 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg10,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_12,
                            className: null,
                            label: {
                                coords: [661, 471],
                            },
                            infobox: {
                                type: "land_info",
                                index: 11,
                                lot: "11",
                                total_lot_size: "629 ",
                                forest_area: "276 ",
                                buildable_area: "251 ",
                                max_suggested_villa_floor_area: "602-753 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg11,
                                price: "¥218,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_13,
                            className: null,
                            label: {
                                coords: [724, 551],
                            },
                            infobox: {
                                type: "land_info",
                                index: 12,
                                lot: "12",
                                total_lot_size: "633 ",
                                forest_area: "274 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "614-767 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg12,
                                price: "¥232,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_14,
                            className: null,
                            label: {
                                coords: [786, 639],
                            },
                            infobox: {
                                type: "land_info",
                                index: 15,
                                lot: "15",
                                total_lot_size: "657 ",
                                forest_area: "289 ",
                                buildable_area: "265 ",
                                max_suggested_villa_floor_area: "638-797 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "held",
                                image: LotImg15,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_15,
                            className: null,
                            label: {
                                coords: [867, 720],
                            },
                            infobox: {
                                type: "land_info",
                                index: 16,
                                lot: "16",
                                total_lot_size: "671 ",
                                forest_area: "291 ",
                                buildable_area: "269 ",
                                max_suggested_villa_floor_area: "646-807 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg16,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_16,
                            className: null,
                            label: {
                                coords: [997, 775],
                            },
                            infobox: {
                                type: "land_info",
                                index: 17,
                                lot: "17",
                                total_lot_size: "615 ",
                                forest_area: "271 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "593-741 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg17,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_17,
                            className: null,
                            label: {
                                coords: [1115, 839],
                            },
                            infobox: {
                                type: "land_info",
                                index: 18,
                                lot: "18",
                                total_lot_size: "637 ",
                                forest_area: "273 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "612-765 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg18,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_18,
                            className: null,
                            label: {
                                coords: [1206, 926],
                            },
                            infobox: {
                                type: "land_info",
                                index: 19,
                                lot: "19",
                                total_lot_size: "632 ",
                                forest_area: "262 ",
                                buildable_area: "257 ",
                                max_suggested_villa_floor_area: "617-771 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg19,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_19,
                            className: null,
                            label: {
                                coords: [1296, 1027],
                            },
                            infobox: {
                                type: "land_info",
                                index: 20,
                                lot: "20",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg20,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_20,
                            className: null,
                            label: {
                                coords: [1347, 701],
                            },
                            infobox: {
                                type: "land_info",
                                index: 21,
                                lot: "21",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg21,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_21,
                            className: null,
                            label: {
                                coords: [1196, 634],
                            },
                            infobox: {
                                type: "land_info",
                                index: 22,
                                lot: "22",
                                total_lot_size: "607 ",
                                forest_area: "262 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "594-743 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg22,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_22,
                            className: null,
                            label: {
                                coords: [1036, 565],
                            },
                            infobox: {
                                type: "land_info",
                                index: 23,
                                lot: "23",
                                total_lot_size: "606 ",
                                forest_area: "275 ",
                                buildable_area: "242 ",
                                max_suggested_villa_floor_area: "581-726 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg23,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_23,
                            className: null,
                            label: {
                                coords: [920, 468],
                            },
                            infobox: {
                                type: "land_info",
                                index: 25,
                                lot: "25",
                                total_lot_size: "650 ",
                                forest_area: "358 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "487-608 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg25,
                                price: "¥147,500,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_24,
                            className: null,
                            label: {
                                coords: [1067, 474],
                            },
                            infobox: {
                                type: "land_info",
                                index: 26,
                                lot: "26",
                                total_lot_size: "466 ",
                                forest_area: "177 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg26,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_25,
                            className: null,
                            label: {
                                coords: [1215, 525],
                            },
                            infobox: {
                                type: "land_info",
                                index: 27,
                                lot: "27",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg27,
                                price: "¥95,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_6_26,
                            className: null,
                            label: {
                                coords: [1370, 582],
                            },
                            infobox: {
                                type: "land_info",
                                index: 28,
                                lot: "28",
                                total_lot_size: "458 ",
                                forest_area: "167 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg28,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_6_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C1} />
                                );
                            },
                        },
                        {
                            Element: VR360_6_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                );
                            },
                        },
                        {
                            Element: VR360_6_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                );
                            },
                        },
                        {
                            Element: VR360_6_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C4} />
                                );
                            },
                        },
                        {
                            Element: VR360_6_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C5} />
                                );
                            },
                        },
                        {
                            Element: VR360_6_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C6} />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    //index 27
    {
        index: 33,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_27_3,
                            className: null,
                            label: {
                                coords: [1070, 791],
                            },
                            infobox: {
                                type: "land_info",
                                index: 1,
                                lot: "1",
                                total_lot_size: "619 ",
                                forest_area: "337 ",
                                buildable_area: "198 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg1,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_4,
                            className: null,
                            label: {
                                coords: [1138, 663],
                            },
                            infobox: {
                                type: "land_info",
                                index: 2,
                                lot: "2",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg2,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_5,
                            className: null,
                            label: {
                                coords: [1207, 554],
                            },
                            infobox: {
                                type: "land_info",
                                index: 3,
                                lot: "3",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "193 ",
                                max_suggested_villa_floor_area: "465-580 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg3,
                                price: "¥130,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_6,
                            className: null,
                            label: {
                                coords: [1213, 446],
                            },
                            infobox: {
                                type: "land_info",
                                index: 5,
                                lot: "5",
                                total_lot_size: "667 ",
                                forest_area: "273 ",
                                buildable_area: "286 ",
                                max_suggested_villa_floor_area: "687-858 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg5,
                                price: "¥141,230,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_7,
                            className: null,
                            label: {
                                coords: [1275, 368],
                            },
                            infobox: {
                                type: "land_info",
                                index: 6,
                                lot: "6",
                                total_lot_size: "662 ",
                                forest_area: "307 ",
                                buildable_area: "254 ",
                                max_suggested_villa_floor_area: "611-763 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg6,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_8,
                            className: null,
                            label: {
                                coords: [1335, 300],
                            },
                            infobox: {
                                type: "land_info",
                                index: 7,
                                lot: "7",
                                total_lot_size: "666 ",
                                forest_area: "354 ",
                                buildable_area: "222 ",
                                max_suggested_villa_floor_area: "534-667 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg7,
                                price: "¥151,100,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_9,
                            className: null,
                            label: {
                                coords: [1292, 223],
                            },
                            infobox: {
                                type: "land_info",
                                index: 8,
                                lot: "8",
                                total_lot_size: "611 ",
                                forest_area: "281 ",
                                buildable_area: "240 ",
                                max_suggested_villa_floor_area: "576-720 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg8,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_10,
                            className: null,
                            label: {
                                coords: [1167, 185],
                            },
                            infobox: {
                                type: "land_info",
                                index: 9,
                                lot: "9",
                                total_lot_size: "611 ",
                                forest_area: "325 ",
                                buildable_area: "243 ",
                                max_suggested_villa_floor_area: "585-731 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg9,
                                price: "¥221,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_11,
                            className: null,
                            label: {
                                coords: [1074, 226],
                            },
                            infobox: {
                                type: "land_info",
                                index: 10,
                                lot: "10",
                                total_lot_size: "619 ",
                                forest_area: "277 ",
                                buildable_area: "249 ",
                                max_suggested_villa_floor_area: "599-748 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg10,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_12,
                            className: null,
                            label: {
                                coords: [986, 267],
                            },
                            infobox: {
                                type: "land_info",
                                index: 11,
                                lot: "11",
                                total_lot_size: "629 ",
                                forest_area: "276 ",
                                buildable_area: "251 ",
                                max_suggested_villa_floor_area: "602-753 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg11,
                                price: "¥218,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_13,
                            className: null,
                            label: {
                                coords: [896, 315],
                            },
                            infobox: {
                                type: "land_info",
                                index: 12,
                                lot: "12",
                                total_lot_size: "633 ",
                                forest_area: "274 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "614-767 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg12,
                                price: "¥232,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_14,
                            className: null,
                            label: {
                                coords: [802, 366],
                            },
                            infobox: {
                                type: "land_info",
                                index: 15,
                                lot: "15",
                                total_lot_size: "657 ",
                                forest_area: "289 ",
                                buildable_area: "265 ",
                                max_suggested_villa_floor_area: "638-797 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "held",
                                image: LotImg15,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_15,
                            className: null,
                            label: {
                                coords: [710, 417],
                            },
                            infobox: {
                                type: "land_info",
                                index: 16,
                                lot: "16",
                                total_lot_size: "671 ",
                                forest_area: "291 ",
                                buildable_area: "269 ",
                                max_suggested_villa_floor_area: "646-807 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg16,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_16,
                            className: null,
                            label: {
                                coords: [648, 514],
                            },
                            infobox: {
                                type: "land_info",
                                index: 17,
                                lot: "17",
                                total_lot_size: "615 ",
                                forest_area: "271 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "593-741 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg17,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_17,
                            className: null,
                            label: {
                                coords: [565, 590],
                            },
                            infobox: {
                                type: "land_info",
                                index: 18,
                                lot: "18",
                                total_lot_size: "637 ",
                                forest_area: "273 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "612-765 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg18,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_18,
                            className: null,
                            label: {
                                coords: [466, 646],
                            },
                            infobox: {
                                type: "land_info",
                                index: 19,
                                lot: "19",
                                total_lot_size: "632 ",
                                forest_area: "262 ",
                                buildable_area: "257 ",
                                max_suggested_villa_floor_area: "617-771 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg19,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_19,
                            className: null,
                            label: {
                                coords: [325, 706],
                            },
                            infobox: {
                                type: "land_info",
                                index: 20,
                                lot: "20",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg20,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_20,
                            className: null,
                            label: {
                                coords: [655, 804],
                            },
                            infobox: {
                                type: "land_info",
                                index: 21,
                                lot: "21",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg21,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_21,
                            className: null,
                            label: {
                                coords: [756, 676],
                            },
                            infobox: {
                                type: "land_info",
                                index: 22,
                                lot: "22",
                                total_lot_size: "607 ",
                                forest_area: "262 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "594-743 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg22,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_22,
                            className: null,
                            label: {
                                coords: [852, 545],
                            },
                            infobox: {
                                type: "land_info",
                                index: 23,
                                lot: "23",
                                total_lot_size: "606 ",
                                forest_area: "275 ",
                                buildable_area: "242 ",
                                max_suggested_villa_floor_area: "581-726 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg23,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_23,
                            className: null,
                            label: {
                                coords: [986, 456],
                            },
                            infobox: {
                                type: "land_info",
                                index: 25,
                                lot: "25",
                                total_lot_size: "650 ",
                                forest_area: "358 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "487-608 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg25,
                                price: "¥147,500,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_24,
                            className: null,
                            label: {
                                coords: [966, 582],
                            },
                            infobox: {
                                type: "land_info",
                                index: 26,
                                lot: "26",
                                total_lot_size: "466 ",
                                forest_area: "177 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg26,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_25,
                            className: null,
                            label: {
                                coords: [887, 709],
                            },
                            infobox: {
                                type: "land_info",
                                index: 27,
                                lot: "27",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg27,
                                price: "¥95,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_27_26,
                            className: null,
                            label: {
                                coords: [792, 863],
                            },
                            infobox: {
                                type: "land_info",
                                index: 28,
                                lot: "28",
                                total_lot_size: "458 ",
                                forest_area: "167 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg28,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_27_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C1} />
                                );
                            },
                        },
                        {
                            Element: VR360_27_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                );
                            },
                        },
                        {
                            Element: VR360_27_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                );
                            },
                        },
                        {
                            Element: VR360_27_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C4} />
                                );
                            },
                        },
                        {
                            Element: VR360_27_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C5} />
                                );
                            },
                        },
                        {
                            Element: VR360_27_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C6} />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    //index 62
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_62_3,
                            className: null,
                            label: {
                                coords: [620, 601],
                            },
                            infobox: {
                                type: "land_info",
                                index: 1,
                                lot: "1",
                                total_lot_size: "619 ",
                                forest_area: "337 ",
                                buildable_area: "198 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg1,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_4,
                            className: null,
                            label: {
                                coords: [757, 650],
                            },
                            infobox: {
                                type: "land_info",
                                index: 2,
                                lot: "2",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg2,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_5,
                            className: null,
                            label: {
                                coords: [892, 706],
                            },
                            infobox: {
                                type: "land_info",
                                index: 3,
                                lot: "3",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "193 ",
                                max_suggested_villa_floor_area: "465-580 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg3,
                                price: "¥130,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_6,
                            className: null,
                            label: {
                                coords: [1048, 720],
                            },
                            infobox: {
                                type: "land_info",
                                index: 5,
                                lot: "5",
                                total_lot_size: "667 ",
                                forest_area: "273 ",
                                buildable_area: "286 ",
                                max_suggested_villa_floor_area: "687-858 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg5,
                                price: "¥141,230,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_7,
                            className: null,
                            label: {
                                coords: [1166, 781],
                            },
                            infobox: {
                                type: "land_info",
                                index: 6,
                                lot: "6",
                                total_lot_size: "662 ",
                                forest_area: "307 ",
                                buildable_area: "254 ",
                                max_suggested_villa_floor_area: "611-763 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg6,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_8,
                            className: null,
                            label: {
                                coords: [1307, 849],
                            },
                            infobox: {
                                type: "land_info",
                                index: 7,
                                lot: "7",
                                total_lot_size: "666 ",
                                forest_area: "354 ",
                                buildable_area: "222 ",
                                max_suggested_villa_floor_area: "534-667 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg7,
                                price: "¥151,100,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_9,
                            className: null,
                            label: {
                                coords: [1445, 819],
                            },
                            infobox: {
                                type: "land_info",
                                index: 8,
                                lot: "8",
                                total_lot_size: "611 ",
                                forest_area: "281 ",
                                buildable_area: "240 ",
                                max_suggested_villa_floor_area: "576-720 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg8,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_10,
                            className: null,
                            label: {
                                coords: [1460, 657],
                            },
                            infobox: {
                                type: "land_info",
                                index: 9,
                                lot: "9",
                                total_lot_size: "611 ",
                                forest_area: "325 ",
                                buildable_area: "243 ",
                                max_suggested_villa_floor_area: "585-731 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg9,
                                price: "¥221,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_11,
                            className: null,
                            label: {
                                coords: [1352, 561],
                            },
                            infobox: {
                                type: "land_info",
                                index: 10,
                                lot: "10",
                                total_lot_size: "619 ",
                                forest_area: "277 ",
                                buildable_area: "249 ",
                                max_suggested_villa_floor_area: "599-748 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg10,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_12,
                            className: null,
                            label: {
                                coords: [1259, 486],
                            },
                            infobox: {
                                type: "land_info",
                                index: 11,
                                lot: "11",
                                total_lot_size: "629 ",
                                forest_area: "276 ",
                                buildable_area: "251 ",
                                max_suggested_villa_floor_area: "602-753 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg11,
                                price: "¥218,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_13,
                            className: null,
                            label: {
                                coords: [1174, 414],
                            },
                            infobox: {
                                type: "land_info",
                                index: 12,
                                lot: "12",
                                total_lot_size: "633 ",
                                forest_area: "274 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "614-767 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg12,
                                price: "¥232,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_14,
                            className: null,
                            label: {
                                coords: [1087, 345],
                            },
                            infobox: {
                                type: "land_info",
                                index: 15,
                                lot: "15",
                                total_lot_size: "657 ",
                                forest_area: "289 ",
                                buildable_area: "265 ",
                                max_suggested_villa_floor_area: "638-797 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "held",
                                image: LotImg15,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_15,
                            className: null,
                            label: {
                                coords: [1001, 299],
                            },
                            infobox: {
                                type: "land_info",
                                index: 16,
                                lot: "16",
                                total_lot_size: "671 ",
                                forest_area: "291 ",
                                buildable_area: "269 ",
                                max_suggested_villa_floor_area: "646-807 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg16,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_16,
                            className: null,
                            label: {
                                coords: [895, 265],
                            },
                            infobox: {
                                type: "land_info",
                                index: 17,
                                lot: "17",
                                total_lot_size: "615 ",
                                forest_area: "271 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "593-741 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg17,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_17,
                            className: null,
                            label: {
                                coords: [817, 229],
                            },
                            infobox: {
                                type: "land_info",
                                index: 18,
                                lot: "18",
                                total_lot_size: "637 ",
                                forest_area: "273 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "612-765 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg18,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_18,
                            className: null,
                            label: {
                                coords: [757, 189],
                            },
                            infobox: {
                                type: "land_info",
                                index: 19,
                                lot: "19",
                                total_lot_size: "632 ",
                                forest_area: "262 ",
                                buildable_area: "257 ",
                                max_suggested_villa_floor_area: "617-771 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg19,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_19,
                            className: null,
                            label: {
                                coords: [704, 126],
                            },
                            infobox: {
                                type: "land_info",
                                index: 20,
                                lot: "20",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg20,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_20,
                            className: null,
                            label: {
                                coords: [622, 317],
                            },
                            infobox: {
                                type: "land_info",
                                index: 21,
                                lot: "21",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg21,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_21,
                            className: null,
                            label: {
                                coords: [738, 364],
                            },
                            infobox: {
                                type: "land_info",
                                index: 22,
                                lot: "22",
                                total_lot_size: "607 ",
                                forest_area: "262 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "594-743 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg22,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_22,
                            className: null,
                            label: {
                                coords: [879, 413],
                            },
                            infobox: {
                                type: "land_info",
                                index: 23,
                                lot: "23",
                                total_lot_size: "606 ",
                                forest_area: "275 ",
                                buildable_area: "242 ",
                                max_suggested_villa_floor_area: "581-726 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg23,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_23,
                            className: null,
                            label: {
                                coords: [998, 510],
                            },
                            infobox: {
                                type: "land_info",
                                index: 25,
                                lot: "25",
                                total_lot_size: "650 ",
                                forest_area: "358 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "487-608 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg25,
                                price: "¥147,500,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_24,
                            className: null,
                            label: {
                                coords: [845, 502],
                            },
                            infobox: {
                                type: "land_info",
                                index: 26,
                                lot: "26",
                                total_lot_size: "466 ",
                                forest_area: "177 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg26,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_25,
                            className: null,
                            label: {
                                coords: [705, 455],
                            },
                            infobox: {
                                type: "land_info",
                                index: 27,
                                lot: "27",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg27,
                                price: "¥95,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_62_26,
                            className: null,
                            label: {
                                coords: [571, 411],
                            },
                            infobox: {
                                type: "land_info",
                                index: 28,
                                lot: "28",
                                total_lot_size: "458 ",
                                forest_area: "167 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg28,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_62_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C1} />
                                );
                            },
                        },
                        {
                            Element: VR360_62_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                );
                            },
                        },
                        {
                            Element: VR360_62_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                );
                            },
                        },
                        {
                            Element: VR360_62_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C4} />
                                );
                            },
                        },
                        {
                            Element: VR360_62_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C5} />
                                );
                            },
                        },
                        {
                            Element: VR360_62_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C6} />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
    //index 89
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_89_3,
                            className: null,
                            label: {
                                coords: [838, 260],
                            },
                            infobox: {
                                type: "land_info",
                                index: 1,
                                lot: "1",
                                total_lot_size: "619 ",
                                forest_area: "337 ",
                                buildable_area: "198 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg1,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_4,
                            className: null,
                            label: {
                                coords: [783, 348],
                            },
                            infobox: {
                                type: "land_info",
                                index: 2,
                                lot: "2",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg2,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_5,
                            className: null,
                            label: {
                                coords: [714, 437],
                            },
                            infobox: {
                                type: "land_info",
                                index: 3,
                                lot: "3",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "193 ",
                                max_suggested_villa_floor_area: "465-580 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg3,
                                price: "¥130,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_6,
                            className: null,
                            label: {
                                coords: [693, 547],
                            },
                            infobox: {
                                type: "land_info",
                                index: 5,
                                lot: "5",
                                total_lot_size: "667 ",
                                forest_area: "273 ",
                                buildable_area: "286 ",
                                max_suggested_villa_floor_area: "687-858 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg5,
                                price: "¥141,230,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_7,
                            className: null,
                            label: {
                                coords: [622, 654],
                            },
                            infobox: {
                                type: "land_info",
                                index: 6,
                                lot: "6",
                                total_lot_size: "662 ",
                                forest_area: "307 ",
                                buildable_area: "254 ",
                                max_suggested_villa_floor_area: "611-763 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg6,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_8,
                            className: null,
                            label: {
                                coords: [531, 758],
                            },
                            infobox: {
                                type: "land_info",
                                index: 7,
                                lot: "7",
                                total_lot_size: "666 ",
                                forest_area: "354 ",
                                buildable_area: "222 ",
                                max_suggested_villa_floor_area: "534-667 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg7,
                                price: "¥151,100,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_9,
                            className: null,
                            label: {
                                coords: [529, 885],
                            },
                            infobox: {
                                type: "land_info",
                                index: 8,
                                lot: "8",
                                total_lot_size: "611 ",
                                forest_area: "281 ",
                                buildable_area: "240 ",
                                max_suggested_villa_floor_area: "576-720 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg8,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_10,
                            className: null,
                            label: {
                                coords: [714, 952],
                            },
                            infobox: {
                                type: "land_info",
                                index: 9,
                                lot: "9",
                                total_lot_size: "611 ",
                                forest_area: "325 ",
                                buildable_area: "243 ",
                                max_suggested_villa_floor_area: "585-731 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg9,
                                price: "¥221,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_11,
                            className: null,
                            label: {
                                coords: [848, 851],
                            },
                            infobox: {
                                type: "land_info",
                                index: 10,
                                lot: "10",
                                total_lot_size: "619 ",
                                forest_area: "277 ",
                                buildable_area: "249 ",
                                max_suggested_villa_floor_area: "599-748 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg10,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_12,
                            className: null,
                            label: {
                                coords: [963, 770],
                            },
                            infobox: {
                                type: "land_info",
                                index: 11,
                                lot: "11",
                                total_lot_size: "629 ",
                                forest_area: "276 ",
                                buildable_area: "251 ",
                                max_suggested_villa_floor_area: "602-753 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg11,
                                price: "¥218,800,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_13,
                            className: null,
                            label: {
                                coords: [1067, 693],
                            },
                            infobox: {
                                type: "land_info",
                                index: 12,
                                lot: "12",
                                total_lot_size: "633 ",
                                forest_area: "274 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "614-767 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg12,
                                price: "¥232,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_14,
                            className: null,
                            label: {
                                coords: [1162, 612],
                            },
                            infobox: {
                                type: "land_info",
                                index: 15,
                                lot: "15",
                                total_lot_size: "657 ",
                                forest_area: "289 ",
                                buildable_area: "265 ",
                                max_suggested_villa_floor_area: "638-797 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "held",
                                image: LotImg15,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_15,
                            className: null,
                            label: {
                                coords: [1228, 536],
                            },
                            infobox: {
                                type: "land_info",
                                index: 16,
                                lot: "16",
                                total_lot_size: "671 ",
                                forest_area: "291 ",
                                buildable_area: "269 ",
                                max_suggested_villa_floor_area: "646-807 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg16,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_16,
                            className: null,
                            label: {
                                coords: [1260, 442],
                            },
                            infobox: {
                                type: "land_info",
                                index: 17,
                                lot: "17",
                                total_lot_size: "615 ",
                                forest_area: "271 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "593-741 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg17,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_17,
                            className: null,
                            label: {
                                coords: [1304, 371],
                            },
                            infobox: {
                                type: "land_info",
                                index: 18,
                                lot: "18",
                                total_lot_size: "637 ",
                                forest_area: "273 ",
                                buildable_area: "255 ",
                                max_suggested_villa_floor_area: "612-765 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg18,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_18,
                            className: null,
                            label: {
                                coords: [1362, 318],
                            },
                            infobox: {
                                type: "land_info",
                                index: 19,
                                lot: "19",
                                total_lot_size: "632 ",
                                forest_area: "262 ",
                                buildable_area: "257 ",
                                max_suggested_villa_floor_area: "617-771 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg19,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_19,
                            className: null,
                            label: {
                                coords: [1444, 273],
                            },
                            infobox: {
                                type: "land_info",
                                index: 20,
                                lot: "20",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg20,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_20,
                            className: null,
                            label: {
                                coords: [1149, 229],
                            },
                            infobox: {
                                type: "land_info",
                                index: 21,
                                lot: "21",
                                total_lot_size: "627 ",
                                forest_area: "370 ",
                                buildable_area: "166 ",
                                max_suggested_villa_floor_area: "400-499 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg21,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_21,
                            className: null,
                            label: {
                                coords: [1104, 315],
                            },
                            infobox: {
                                type: "land_info",
                                index: 22,
                                lot: "22",
                                total_lot_size: "607 ",
                                forest_area: "262 ",
                                buildable_area: "247 ",
                                max_suggested_villa_floor_area: "594-743 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "sold",
                                image: LotImg22,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_22,
                            className: null,
                            label: {
                                coords: [1047, 426],
                            },
                            infobox: {
                                type: "land_info",
                                index: 23,
                                lot: "23",
                                total_lot_size: "606 ",
                                forest_area: "275 ",
                                buildable_area: "242 ",
                                max_suggested_villa_floor_area: "581-726 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg23,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_23,
                            className: null,
                            label: {
                                coords: [939, 518],
                            },
                            infobox: {
                                type: "land_info",
                                index: 25,
                                lot: "25",
                                total_lot_size: "650 ",
                                forest_area: "358 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "487-608 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg25,
                                price: "¥147,500,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_24,
                            className: null,
                            label: {
                                coords: [938, 400],
                            },
                            infobox: {
                                type: "land_info",
                                index: 26,
                                lot: "26",
                                total_lot_size: "466 ",
                                forest_area: "177 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg26,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_25,
                            className: null,
                            label: {
                                coords: [992, 299],
                            },
                            infobox: {
                                type: "land_info",
                                index: 27,
                                lot: "27",
                                total_lot_size: "612 ",
                                forest_area: "335 ",
                                buildable_area: "199 ",
                                max_suggested_villa_floor_area: "476-595 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "available",
                                image: LotImg27,
                                price: "¥95,000,000",
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                        {
                            Polygon: LandPlot_89_26,
                            className: null,
                            label: {
                                coords: [1039, 208],
                            },
                            infobox: {
                                type: "land_info",
                                index: 28,
                                lot: "28",
                                total_lot_size: "458 ",
                                forest_area: "167 ",
                                buildable_area: "202 ",
                                max_suggested_villa_floor_area: "486-607 ",
                                description:
                                    "*1: Simplified estimated range assuming a 3 storey building above ground with flat roof, based upon current building coverage ratio, floor area ratio, height limit, and setback regulations. Figures are not guaranteed, and are subject to variance pending architect clarifications in accordance with an Owner's design requirements.",
                                status: "phase 2",
                                image: LotImg28,
                            },
                            onClick: {
                                action: "open_enquire_window",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: VR360_89_1,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C1} />
                                );
                            },
                        },
                        {
                            Element: VR360_89_2,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C2} />
                                );
                            },
                        },
                        {
                            Element: VR360_89_3,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C3} />
                                );
                            },
                        },
                        {
                            Element: VR360_89_4,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C4} />
                                );
                            },
                        },
                        {
                            Element: VR360_89_5,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C5} />
                                );
                            },
                        },
                        {
                            Element: VR360_89_6,
                            className: "vr360",
                            onClick: () => {
                                setEnquireWindowContent(null);
                                setModalContent(
                                    <VR id="walking-area" panorama={VR_C6} />
                                );
                            },
                        },
                    ]}
                />
            </>
        ),
    },
];
