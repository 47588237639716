import "pannellum";

import "./VR.scss";
import { useEffect } from "react";
import { VRExit } from "../../modal/Modal";

// import crossImg from "../../../static/images/Close.svg"

export default function VR({ id, panorama, closeFunc }) {
    useEffect(() => {
        if (id && panorama) {
            window.pannellum.viewer(id, {
                autoLoad: true,
                showControls: false,
                type: "equirectangular",
                panorama: panorama,
            });
        }
    }, [id, panorama]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(event.key === "Escape"){
                closeFunc();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [closeFunc]);

    return (
        <>
            <VRExit closeFunc={closeFunc} />
            <div className="vr" id={id} />
        </>
    );
}
