import {
    RouterProvider,
    createBrowserRouter,
    Navigate,
    Link,
} from "react-router-dom";

import "./App.scss";

import { IncludeLocalization } from "./components/localization/Localization";

import Main from "./pages/main/Main";
import GolfEstates from "./pages/main/contents/golf-estates/GolfEstates";
import MasterPlan from "./pages/main/contents/master-plan/MasterPlan";
import PageTransition from "./animations/PageTransition";

const router = createBrowserRouter([
    { path: "/", element: <Navigate to="/en/" replace /> },
    { path: "*", element: <Navigate to="/" replace /> },
    {
        path: "/:lang",
        element: <IncludeLocalization />,
        children: [
            {
                path: "",
                element: <Main />,
                children: [
                    {
                        path: "",
                        element: (
                            <PageTransition>
                                <MasterPlan />
                            </PageTransition>
                        ),
                    },
                    {
                        path: "golf-estates",
                        element: (
                            <PageTransition>
                                <GolfEstates />
                            </PageTransition>
                        ),
                    },
                ],
            },
        ],
    },
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
